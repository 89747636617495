<template>
  <nav >
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" top :color="snackbar.color">
      <span>{{ snackbar.message }}</span>
      <template v-slot:action>
        <v-btn color="white" dark text @click="closeSnackbar">Close</v-btn>
      </template>
    </v-snackbar>

    <v-app-bar app flat dark color="primary" height="50">
      <template v-for="(item, i) in toolbarNavigationAdmin">
        <template v-if="item.heading">
          <v-toolbar-title :key="`${i}-toolbar-link`" @click="navigationClick(item)" :style="{ cursor: 'pointer'}">
            {{ item.title }}
          </v-toolbar-title>
        </template>

        <v-spacer v-else-if="item.spacer" :key="`${i}-toolbar-link`"></v-spacer>

        <template v-else-if="(item.logged && user) || (!item.logged && user == null)">
          <v-toolbar-items :key="`${item.title}-toolbar-link`" v-show="authReady">
            <v-btn text class="hidden-xs-only" @click="navigationClick(item)">{{ item.title }}</v-btn>
          </v-toolbar-items>
        </template>
      </template>
    </v-app-bar>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

export default {
  name: "NavBarAdmin",

  computed: {
    ...mapGetters(["toolbarNavigationAdmin", "snackbar", "authReady", "user"]),
  },

  methods: {
    navigationClick(link) {
      if (link.action == "link") {
        this.routeLink(link.routeName);
      } else if (link.action == "url") {
        this.openUrl(link.path);
      } else if (link.action == "register") {
        this.$store.dispatch("openRegisterDialog", true);
      } else if (link.action == "login") {
        this.$store.dispatch("openLoginDialog", true);
      } else if (link.action == "logoff") {
        this.$store.dispatch("logoff", true);
        this.routeLink("Admin");
      }
    },
    routeLink(name) {
      router.push({ name: name }).catch(() => {});
    },
    openUrl(url) {
      window.open(url);
    },
    closeSnackbar() {
      this.$store.dispatch("closeSnackbar");
    },
  },
};
</script>