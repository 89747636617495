export default {
  property(state) {
    return state.property;
  },
  propertyAddress(state) {
    return {
      line1: state.property.address.street,
      line2:
        state.property.address.city +
        ", " +
        state.property.address.state +
        " " +
        state.property.address.postalCode
    };
  },
  toolbarNavigation(state) {
    return state.toolbarNavigation;
  },
  sideNavigation(state) {
    return state.sideNavigation;
  },
  sideNavigationImages(state) {
    return state.sideNavigationImages;
  },
  pageNavigation(state) {
    return state.pageNavigation;
  },
  carouselImages(state) {
    let list = state.carouselImages;

    if (list) {
      list = list.sort((a, b) => a.order - b.order);
    }

    return list
  },
  propertyDetails(state) {
    return state.propertyDetails;
  },
  lightboxImages(state) {
    let list = state.lightboxImages;

    if (list) {
      list = list.sort((a, b) => a.order - b.order);
    }

    return list
  },
  floorPlanImages(state) {
    return state.floorPlanImages;
  },
  amenities(state) {
    return state.amenities;
  },
  amenitiesImages(state) {
    return state.amenitiesImages;
  },
  amenitiesList(state) {
    return state.amenitiesList;
  },
  amenitiesPropertyList(getters) {
    let list = [];

    const lists = getters.amenitiesList.filter(
      item => item.header == "Property"
    );

    if (lists && lists.length == 1) {
      const item = lists[0];
      if (item.list) {
        list = item.list.sort((a, b) => a.order - b.order);
      }
    }

    return list;
  },
  amenitiesUnitList(getters) {
    let list = [];

    const lists = getters.amenitiesList.filter(item => item.header == "Unit");

    if (lists && lists.length == 1) {
      const item = lists[0];
      if (item.list) {
        list = item.list.sort((a, b) => a.order - b.order);
      }
    }

    return list;
  },
  utilities(state) {
    return state.utilities;
  },
  utilitiesImages(state) {
    return state.utilitiesImages;
  },
  utilitiesList(state) {
    return state.utilitiesList;
  },
  utilitiesPaidByLandlordList(getters) {
    let list = [];

    const lists = getters.utilitiesList.filter(
      item => item.header == "Paid By Landlord"
    );

    if (lists && lists.length == 1) {
      const item = lists[0];
      if (item.list) {
        list = item.list.sort((a, b) => a.order - b.order);
      }
    }

    return list;
  },
  utilitiesPaidByTenantList(getters) {
    let list = [];

    const lists = getters.utilitiesList.filter(
      item => item.header == "Paid By Tenant"
    );

    if (lists && lists.length == 1) {
      const item = lists[0];
      if (item.list) {
        list = item.list.sort((a, b) => a.order - b.order);
      }
    }

    return list;
  },
  virtualTour(state) {
    return state.virtualTour;
  },
  neighborhoodList(state) {
    return state.neighborhoodList;
  },
  neighborhoodEntertainmentList(getters) {
    let list = [];

    if (!getters.neighborhoodList) {
      return list
    }

    const lists = getters.neighborhoodList.filter(
      item => item.header == "Entertainment"
    );

    if (lists && lists.length == 1) {
      const item = lists[0];
      if (item.list) {
        list = item.list.sort((a, b) => a.order - b.order);
      }
    }

    return list;
  },
  neighborhoodShoppingList(getters) {
    let list = [];

    if (!getters.neighborhoodList) {
      return list
    }

    const lists = getters.neighborhoodList.filter(
      item => item.header == "Shopping"
    );

    if (lists && lists.length == 1) {
      const item = lists[0];
      if (item.list) {
        list = item.list.sort((a, b) => a.order - b.order);
      }
    }

    return list;
  },
  neighborhoodDiningList(getters) {
    let list = [];

    if (!getters.neighborhoodList) {
      return list
    }

    const lists = getters.neighborhoodList.filter(
      item => item.header == "Dining"
    );

    if (lists && lists.length == 1) {
      const item = lists[0];
      if (item.list) {
        list = item.list.sort((a, b) => a.order - b.order);
      }
    }

    return list;
  },
  neighborhoodGroceriesList(getters) {
    let list = [];

    if (!getters.neighborhoodList) {
      return list
    }

    const lists = getters.neighborhoodList.filter(
      item => item.header == "Groceries"
    );

    if (lists && lists.length == 1) {
      const item = lists[0];
      if (item.list) {
        list = item.list.sort((a, b) => a.order - b.order);
      }
    }

    return list;
  },
  neighborhoodBanksList(getters) {
    let list = [];

    if (!getters.neighborhoodList) {
      return list
    }

    const lists = getters.neighborhoodList.filter(
      item => item.header == "Banks"
    );

    if (lists && lists.length == 1) {
      const item = lists[0];
      if (item.list) {
        list = item.list.sort((a, b) => a.order - b.order);
      }
    }

    return list;
  },
  neighborhoodFitnessList(getters) {
    let list = [];

    if (!getters.neighborhoodList) {
      return list
    }

    const lists = getters.neighborhoodList.filter(
      item => item.header == "Fitness"
    );

    if (lists && lists.length == 1) {
      const item = lists[0];
      if (item.list) {
        list = item.list.sort((a, b) => a.order - b.order);
      }
    }

    return list;
  },
  virtualTourList(state) {
    return state.virtualTourList.sort((a, b) => a.order - b.order);
  },
  contactUsDialog(state) {
    return state.contactUsDialog;
  },
  contactUsLoading(state) {
    return state.contactUsLoading;
  },
  applyHereLoading(state) {
    return state.applyHereLoading;
  },
  tvImages(state) {
    let list = state.tvImages;

    if (list) {
      list = list.sort((a, b) => a.order - b.order);
    }

    return list
  },
  tvLog(state) {
    return state.tvLog
  },
  snackbar(state) {
    return state.snackbar;
  },
  authReady(state) {
    return state.authReady;
  },
  user(state) {
    return state.user;
  },
  loginDialogType(state) {
    return state.loginDialogType;
  },
  loginDialog(state) {
    return state.loginDialog;
  },
  loginLoading(state) {
    return state.loginLoading;
  },
  toolbarNavigationAdmin(state) {
    if (!state.authReady) {
      return [];
    } else {
      return state.toolbarNavigationAdmin.filter(link => link.show === true);
    }
  },
  applyHereList(state) {
    return state.applyHereList;
  },
  contactUsList(state) {
    return state.contactUsList;
  }
};
