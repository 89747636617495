<template>
    <v-row justify="center">
        <v-dialog v-model="contactUsDialog" persistent :fullscreen="$vuetify.breakpoint.smAndDown" max-width="600px">
            <v-card>
                <v-card-title class="card-title">
                    <span class="headline primary--text text--lighten-1" :class="{'padding-title': $vuetify.breakpoint.mdAndUp }">Contact Us</span>
                </v-card-title>

                <v-form ref="form">
                  <v-card-text>
                      <v-container>
                          <v-row>
                              <v-col cols="12" sm="6" :class="{'padding-input': $vuetify.breakpoint.smAndDown }">
                                  <v-text-field label="First name" v-model="firstName" :rules="inputRules">
                                    <v-icon slot="prepend" color="primary lighten-1">mdi-account</v-icon>
                                  </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" :class="{'padding-input': $vuetify.breakpoint.smAndDown }">
                                  <v-text-field label="Last name" v-model="lastName" prepend-icon="mdi-empty" :rules="inputRules"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4" :class="{'padding-input': $vuetify.breakpoint.smAndDown }">
                                  <v-text-field label="Phone" v-model="phone" v-mask="phoneMask" :rules="phoneRules">
                                    <v-icon slot="prepend" color="primary lighten-1">mdi-phone</v-icon>
                                  </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="8" :class="{'padding-input': $vuetify.breakpoint.smAndDown }">
                                  <v-text-field label="Email" v-model="email" :rules="emailRules">
                                    <v-icon slot="prepend" color="primary lighten-1">mdi-email</v-icon>
                                  </v-text-field>
                              </v-col>
                              <v-col cols="12" :class="{'padding-input': $vuetify.breakpoint.smAndDown }">
                                  <v-textarea name="message" label="Message" v-model="message" style="margin-bottom: -40px;" :rules="inputRules">
                                    <v-icon slot="prepend" color="primary lighten-1">mdi-message-text-outline</v-icon>
                                  </v-textarea>
                              </v-col>
                          </v-row>
                      </v-container>
                      
                  </v-card-text>

                  <v-card-actions :class="{ 'padding-action-small': $vuetify.breakpoint.smAndDown, 'padding-action': $vuetify.breakpoint.mdAndUp }">
                      <v-btn color="primary darken-1" text dark @click="cancel">Cancel</v-btn>
                      <div class="flex-grow-1"></div>
                      <v-btn color="primary darken-1" text dark @click="sendMessage" :loading="contactUsLoading">Send Message</v-btn>
                  </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { MASK } from "@/constants/Masks";

export default {
  name: "ContactUs",

  directives: {
    mask,
  },

  data() {
    return {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      message: "",
      inputRules: [(v) => (!!v && v.length > 0) || "Field cannot be empty"],
      phoneRules: [(v) => (!!v && v.length > 13) || "Phone must be valid"],
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ],
      phoneMask: MASK.phone,
    };
  },

  computed: {
    ...mapGetters(["contactUsDialog", "contactUsLoading"]),
  },

  methods: {
    cancel() {
      this.$store.dispatch("openContactUsDialog", false);
      this.resetForm();
    },
    sendMessage() {
      let source = this.$route.query.source;

      if (source == undefined || source.length == 0) {
        source = "website";
      }

      if (this.$refs.form.validate()) {
        const form = {
          source: source,
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          email: this.email,
          message: this.message,
          createdDate: new Date(),
        };

        this.$store.dispatch("submitContactUs", form);
        this.resetForm();
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped>
.card-title {
  padding: 5px 20px 0px 30px;
}

.padding-title {
  padding: 15px 5px;
}

.padding-input {
  padding: 0 10px;
}

.padding-action {
  padding: 5px 30px 20px 30px;
}

.padding-action-small {
  padding: 5px 20px 10px 30px;
}
</style>