import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/Home.vue";
import store from "@/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/property-details",
      name: "PropertyDetails",
      component: () =>
        import(/* webpackChunkName: "property-details" */ "../views/Home.vue")
    },
    {
      path: "/virtual-tour",
      name: "VirtualTour",
      component: () =>
        import(
          /* webpackChunkName: "virtual-tour" */ "../views/VirtualTour.vue"
        )
    },
    {
      path: "/neighborhood",
      name: "Neighborhood",
      component: () =>
        import(
          /* webpackChunkName: "neighborhood" */ "../views/Neighborhood.vue"
        )
    },
    {
      path: "/apply-here",
      name: "ApplyHere",
      component: () =>
        import(/* webpackChunkName: "apply-here" */ "../views/ApplyHere.vue")
    },
    {
      path: "/admin",
      name: "Admin",
      component: () =>
        import(/* webpackChunkName: "admin" */ "../views/admin/Admin.vue")
    },
    {
      path: "/admin/side-navigation",
      name: "SideNavigationAdmin",
      component: () =>
        import(
          /* webpackChunkName: "side-navigation" */ "../views/admin/SideNavigation.vue"
        ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/admin/property-details",
      name: "PropertyDetailsAdmin",
      component: () =>
        import(
          /* webpackChunkName: "property-details-admin" */ "../views/admin/PropertyDetails.vue"
        ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/admin/neighborhood",
      name: "NeighborhoodAdmin",
      component: () =>
        import(
          /* webpackChunkName: "neighborhood-admin" */ "../views/admin/Neighborhood.vue"
        ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/admin/virtual-tour",
      name: "VirtualTourAdmin",
      component: () =>
        import(
          /* webpackChunkName: "virtual-tour-admin" */ "../views/admin/VirtualTour.vue"
        ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/admin/apply-here",
      name: "ApplyHereAdmin",
      component: () =>
        import(
          /* webpackChunkName: "apply-here-admin" */ "../views/admin/ApplyHere.vue"
        ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/admin/contact-us",
      name: "ContactUsAdmin",
      component: () =>
        import(
          /* webpackChunkName: "contact-us-admin" */ "../views/admin/ContactUs.vue"
        ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/admin/tv",
      name: "TVAdmin",
      component: () =>
        import(
          /* webpackChunkName: "tv-admin" */ "../views/admin/TV.vue"
        ),
      meta: {
        requiresAuth: true
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.user) {
      next({
        path: "/admin",
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
