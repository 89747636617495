<template>
  <nav >
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" top :color="snackbar.color">
      <span>{{ snackbar.message }}</span>
      <template v-slot:action>
        <v-btn color="white" dark text @click="closeSnackbar">Close</v-btn>
      </template>
    </v-snackbar>

    <v-app-bar app flat dark color="primary" height="50">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>

      <template v-for="(item, i) in toolbarNavigation">
        <template v-if="item.heading">
          <v-toolbar-title :key="`${i}-toolbar-link`" @click="navigationClick(item)" :style="{ cursor: 'pointer'}">
            {{ item.title }}
          </v-toolbar-title>
        </template>

        <v-spacer v-else-if="item.spacer" :key="`${i}-toolbar-link`"></v-spacer>

        <template v-else-if="item.hiddenXsOnlyButton">
          <v-toolbar-items :key="`${i}-toolbar-link`">
            <v-btn text class="hidden-xs-only" @click="navigationClick(item)">{{ item.title }}</v-btn>
          </v-toolbar-items>
        </template>

        <template v-else-if="item.hiddenXsOnlyIcon">
          <v-toolbar-items :key="`${i}-toolbar-link`">
            <v-btn icon class="hidden-xs-only" @click="navigationClick(item)">
              <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
          </v-toolbar-items>
        </template>

        <template v-else-if="item.hiddenSmAndDown">
          <v-toolbar-items :key="`${i}-toolbar-link`">
            <v-btn text class="hidden-sm-and-down" @click="navigationClick(item)">{{ item.title }}</v-btn>
          </v-toolbar-items>
        </template>
      </template>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" temporary width="320px">
      <v-layout row>
        <v-flex xs12>
          <v-card flat>
            <v-list class="pt-0">
              <template v-for="(item, i) in sideNavigation">
                <v-layout v-if="item.heading" :key="`${item.title}-sidebar-link`" align-center>
                  <v-img :src="item.image" height="200px">
                    <v-layout column fill-height>
                      <v-card-title>
                        <v-btn fab dark small color="white" icon @click="drawer = false">
                          <v-icon>mdi-chevron-left-circle-outline</v-icon>
                        </v-btn>
                      </v-card-title>
                      
                      <v-spacer></v-spacer>

                      <v-card-title class="white--text pl-5 pt-3">
                        <span class="headline shadow white--text">{{ item.title }}</span>
                      </v-card-title>
                    </v-layout>
                  </v-img>
                </v-layout>

                <v-divider v-else-if="item.divider" :key="i" dark />

                <v-list-item v-else-if="item.twoLine" :key="`${item.title}-sidebar-link`" two-line @click="navigationClick(item)">
                  <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.subTitle }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-else :key="`${item.title}-sidebar-link`" @click="navigationClick(item)">
                  <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                      <v-list-item-title>
                        {{ item.title }}
                      </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>

    <v-tabs id="navigationTabs" fixed-tabs class="hidden-sm-and-down sticky" style="margin-top: 49px;" height="50">
      <v-tab v-for="item in pageNavigation" :key="`${item.title}-tab-link`" :to="item.route" v-show="item.visible == true" style="margin-left: 0px;">
        {{ item.title }}
      </v-tab>
    </v-tabs>

    <ContactUs class="sticky" />
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";
import ContactUs from "@/components/ContactUs";

export default {
  name: "NavBar",

  components: {
    ContactUs,
  },

  data() {
    return {
      drawer: false,
    };
  },

  computed: {
    ...mapGetters([
      "toolbarNavigation",
      "sideNavigation",
      "pageNavigation",
      "contactUsDialog",
      "snackbar",
    ]),
  },

  methods: {
    navigationClick(link) {
      if (link.action == "link") {
        this.routeLink(link.routeName);
      } else if (link.action == "url") {
        this.openUrl(link.path);
      } else if (link.action == "phone") {
        this.dialPhone(link.path);
      } else if (link.action == "email") {
        this.sendEmail(link.path);
      } else if (link.action == "map") {
        this.openMap(link.path, link.options);
      } else if (link.action == "contact") {
        this.$store.dispatch("openContactUsDialog", true);
      }
    },
    routeLink(name) {
      router.push({ name: name }).catch(() => {});
    },
    openUrl(url) {
      window.open(url);
    },
    dialPhone(phone) {
      window.open("tel:" + phone.replace(/[^0-9]/g, ""), "_self", false);
    },
    sendEmail(email) {
      window.open("mailto:" + email, "_self", false);
    },
    openMap(coord, options) {
      let url = "";

      if (
        navigator.platform.indexOf("iPhone") != -1 ||
        navigator.platform.indexOf("iPad") != -1 ||
        navigator.platform.indexOf("iPod") != -1
      )
        url = "maps://maps.google.com/maps?daddr=" + coord + "&amp;ll=";
      else {
        url = "https://www.google.com/maps/search/?api=1&query=" + coord;
        if (options) url += "&query_place_id=" + options;
      }

      window.open(url);
    },
    closeSnackbar() {
      this.$store.dispatch("closeSnackbar");
    },
  },
};
</script>

<style scoped>
.shadow {
  text-shadow: rgb(0, 0, 0) 1px 1px 5px;
}
</style>