<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <h1 class="primary--text text--lighten-1" :class="{'h1-sm': $vuetify.breakpoint.xs}">
            Amenities
          </h1>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" v-if="$vuetify.breakpoint.mdAndUp">
        <v-container>
          <v-row>
            <v-col v-for="(image, i) in items" :key="i">
              <v-card flat tile class="d-flex rounded-card">
                <v-img :src="image.url"
                  :lazy-src="image.url" class="grey lighten-2">
                  <template v-slot:placeholder>
                    <v-layout fill-height align-center justify-center ma-0>
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-layout>
                  </template>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="6">
            <v-container>
              <v-row align="center" justify="center">
                <v-card flat style="background-color: transparent">
                  <div class="primary--text text--darken-1 list-header" :class="{'list-header-sm': $vuetify.breakpoint.xs}">
                    Property
                  </div>
                  <ul class="list">
                    <li v-for="(item, i) in propertyList" :key="i"><div v-html="item.title"></div></li>
                  </ul>
                </v-card>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="6">
            <v-container>
              <v-row align="center" justify="center">
                <v-card flat style="background-color: transparent">
                  <div class="primary--text text--darken-1 list-header" :class="{'list-header-sm': $vuetify.breakpoint.xs}">
                    Unit
                  </div>
                  <ul class="list">
                    <li v-for="(item, i) in unitList" :key="i"><div v-html="item.title"></div></li>
                  </ul>
                </v-card>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Amenities",

  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    propertyList: {
      type: Array,
      required: true,
      default: () => []
    },
    unitList: {
      type: Array,
      required: true,
      default: () => []
    }
  }
};
</script>

<style scoped>
.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.rounded-card {
  border-radius: 5px;
}
</style>