<template>
  <v-layout v-resize="onResize" column align-center justify-center>
    <v-layout wrap class="hidden-sm-and-down">
        <v-flex d-flex xs12 justify-center align-center style="height: 50px;">
            &nbsp;
        </v-flex>
    </v-layout>

    <v-carousel 
      :cycle="cycle"
      :height="carouselHeight" 
      hide-delimiter-background 
      :hide-delimiters="controlsVisible" 
      :show-arrows="!controlsVisible"
      delimiter-icon="mdi-circle-medium">
      <v-carousel-item v-for="(image, index) in carouselImages" :key="index">
        <img :src="image.url" :style="{ height: carouselHeight + 'px', width: '100%' }" @click="cycle=!cycle"/>
        <div>
          <div v-if="$vuetify.breakpoint.smAndDown" class="slide-text" :style="{ top: textTopPos(image) - image.underlayMargin / 2 + 'px', 
            left: textLeftPos(image) - image.underlayMargin + 'px', 
            height: image.underlayHeight * divScale + 'px',
            width: image.underlayWidth * divScale + 'px',
            backgroundColor: image.underlayColor,
            opacity: image.underlayOpacity,
            borderRadius: image.underlayMargin * divScale / 2 + 'px' }"></div>
          <div v-if="$vuetify.breakpoint.mdAndUp" class="slide-text" :style="{ top: textTopPos(image) - image.underlayMargin / 2 + 'px', 
            left: textLeftPos(image) - image.underlayMargin + 'px', 
            height: image.underlayHeight + 'px',
            width: image.underlayWidth + 'px',
            backgroundColor: image.underlayColor,
            opacity: image.underlayOpacity,
            borderRadius: image.underlayMargin / 2 + 'px' }"></div>
          <div class="slide-text" :style="{ top: textTopPos(image) + 'px', 
            left: textLeftPos(image) + 'px', 
            color: image.textColor, 
            textShadow: '1px 1px 5px rgba(' + image.textShadow + ')'}">
            <div :class="{'slide-title-sm': $vuetify.breakpoint.smAndDown, 'slide-title': $vuetify.breakpoint.mdAndUp}">{{ image.line1 }}</div>
            <div :class="{'slide-sub-title-sm': $vuetify.breakpoint.smAndDown, 'slide-sub-title': $vuetify.breakpoint.mdAndUp}">{{ image.line2 }}</div>
            <div :class="{'slide-sub-title-sm': $vuetify.breakpoint.smAndDown, 'slide-sub-title': $vuetify.breakpoint.mdAndUp}">{{ image.line3 }}</div>
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CarouselHome",

  data() {
    return {
      carouselHeight: 300,
      carouselWidth: 300,
      cycle: true,
      windowSize: {
        x: 0,
        y: 0
      },
      controlsVisible: true,
      divScale: 0.75
    };
  },

  computed: {
    ...mapGetters(["carouselImages"])
  },

  methods: {
    onResize() {
      this.cycle = false;

      this.windowSize = { x: window.innerWidth, y: window.innerHeight };

      this.carouselWidth = this.windowSize.x;
      this.carouselHeight = this.carouselWidth * 0.5625;

      if (this.windowSize.x > 960) {
        this.controlsVisible = false;
      } else {
        this.controlsVisible = true;
      }

      this.cycle = true;
    },
    textLeftPos(image) {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.carouselWidth * image.textLeftDefault;
      }
      return this.carouselWidth * image.textLeft;
    },
    textTopPos(image) {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.carouselHeight * image.textTopDefault;
      }
      return this.carouselHeight * image.textTop;
    }
  },

  created() {
    this.$store.dispatch("getCarouselImages");
  },

  mounted() {
    this.onResize();
  }
};
</script>

<style scoped>
.slide-text {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.slide-title {
  font-size: 1.3em;
}

.slide-title-sm {
  font-size: 1em;
}

.slide-sub-title {
  font-size: 1.1em;
}

.slide-sub-title-sm {
  font-size: 0.8em;
}
</style>