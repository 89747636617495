<template>
  <div>
    <HomeCarousel />
    <PropertyDetailsContent :property= property :propertyDetails= propertyDetails :items= lightboxImages @show="show" />
    <FloorPlans :items= floorPlanImages />
    <Amenities :items= amenitiesImages :propertyList= amenitiesPropertyList :unitList= amenitiesUnitList />
    <Utilities :items= utilitiesImages :paidByLandlordList= utilitiesPaidByLandlordList :paidByTenantList= utilitiesPaidByTenantList />

    <v-container fluid fill-height class="lightbox" style="max-height: 100vh;" v-if="lightbox.visible" @click.stop="hide">
      <v-layout row v-resize="onResize">
        <v-flex xs12>
          <v-layout justify-center align-center class="lightbox-height" @click.stop="hide">
            <v-layout justify-center align-center class="lightbox-image">
              <img id="lightboxImage" :src="lightboxImages[lightbox.index].url" />
            </v-layout>
          </v-layout>
        </v-flex>
      </v-layout>

      <div class="lightbox-nav" :style="{ padding: lightbox.navHeight + 'px 0'}" @click.stop="prev" :class="{'invisible': ! hasPrev()}">
        <svg fill="#fff" height="48" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"/>
            <path d="M0-.5h24v24H0z" fill="none"/>
        </svg>
      </div>

      <div class="lightbox-nav" :style="{ padding: lightbox.navHeight + 'px 0', right: '0'}" @click.stop="next" :class="{'invisible': ! hasNext()}">
        <svg fill="#fff" height="48" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"/>
            <path d="M0-.25h24v24H0z" fill="none"/>
        </svg>
      </div>

      <div class="lightbox-page" :style="{ top: lightbox.closeButtonTop + 'px', left: lightbox.line1Left + 'px' }">
        {{ lightbox.index + 1 }} of {{ lightboxImages.length}}
      </div>

      <div class="lightbox-close-button" :style="{ top: lightbox.closeButtonTop + 'px', left: lightbox.closeButtonLeft + 'px' }">
        <svg height="48" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <line x1="1" y1="11" x2="11" y2="1" stroke="white" stroke-width="2"/>
          <line x1="1" y1="1" x2="11" y2="11" stroke="white" stroke-width="2"/>
        </svg>
      </div>

      <div class="lightbox-line1" :style="{ top: lightbox.line1Top + 'px', left: lightbox.line1Left + 'px' }">
        {{ lightboxImages[lightbox.index].line1 }}
      </div>

      <div class="lightbox-line2" :style="{ top: lightbox.line2Top + 'px', left: lightbox.line2Left + 'px' }">
        {{ lightboxImages[lightbox.index].line2 }}
      </div>

      <div class="lightbox-line3" :style="{ top: lightbox.line3Top + 'px', left: lightbox.line3Left + 'px' }">
        {{ lightboxImages[lightbox.index].line3 }}
      </div>
    </v-container>
  </div>
</template>

<script>
import HomeCarousel from "@/components/HomeCarousel";
import PropertyDetailsContent from "@/components/PropertyDetailsContent";
import FloorPlans from "@/components/FloorPlans";
import Amenities from "@/components/Amenities";
import Utilities from "@/components/Utilities";
import { mapGetters } from "vuex";

export default {
  name: "Home",

  components: {
    HomeCarousel,
    PropertyDetailsContent,
    FloorPlans,
    Amenities,
    Utilities
  },

  data() {
    return {
      lightbox: {
        index: 0,
        visible: false,
        imageHeight: 0,
        imageWidth: 0,
        closeButtonTop: 0,
        closeButtonLeft: 0,
        navHeight: 0,
        line1Top: 0,
        line1Left: 0,
        line2Top: 0,
        line2Left: 0,
        line3Top: 0,
        line3Left: 0
      }
    };
  },

  computed: {
    ...mapGetters([
      "property",
      "lightboxImages",
      "floorPlanImages",
      "propertyDetails",
      "amenitiesImages",
      "amenitiesPropertyList",
      "amenitiesUnitList",
      "utilities",
      "utilitiesImages",
      "utilitiesPaidByLandlordList",
      "utilitiesPaidByTenantList"
    ]),
    lightboxImagesPage() {
      return this.$store.getters.lightboxImages.slice(0, 6);
    }
  },

  methods: {
    onResize() {
      var image = document.querySelector("#lightboxImage");

      if (!image) {
        return;
      }

      const lightboxImageHeight = image.offsetHeight;
      if (lightboxImageHeight == this.lightbox.imageHeight) {
        return;
      }

      this.lightbox.imageHeight = lightboxImageHeight;

      this.lightbox.navHeight = (lightboxImageHeight - 54) / 2;

      this.windowSize = { x: window.innerWidth, y: window.innerHeight };

      if (this.windowSize.y > this.windowSize.x) {
        this.lightbox.closeButtonTop =
          (this.windowSize.y - lightboxImageHeight) / 2 - 48;
        this.lightbox.line1Top =
          (this.windowSize.y + lightboxImageHeight) / 2 + 24;
      } else {
        this.lightbox.closeButtonTop = 24;
        this.lightbox.line1Top = this.windowSize.y - 150;
      }

      this.lightbox.closeButtonLeft = this.windowSize.x * 0.85;

      this.lightbox.line1Left = this.windowSize.x / 12;

      this.lightbox.line2Top = this.lightbox.line1Top + 30;
      this.lightbox.line2Left = this.lightbox.line1Left;

      this.lightbox.line3Top = this.lightbox.line2Top + 30;
      this.lightbox.line3Left = this.lightbox.line1Left;
    },
    show(index) {
      this.lightbox.index = parseInt(index);
      this.lightbox.visible = true;
    },
    hide() {
      this.lightbox.visible = false;
      this.lightbox.index = 0;
    },
    hasNext() {
      return this.lightbox.index + 1 < this.lightboxImages.length;
    },
    hasPrev() {
      return this.lightbox.index - 1 >= 0;
    },
    prev() {
      if (this.hasPrev()) {
        this.lightbox.index -= 1;
      }
    },
    next() {
      if (this.hasNext()) {
        this.lightbox.index += 1;
      }
    },
    imageLoaded() {
      this.onResize();
    },
    onKeydown(e) {
      if (this.lightbox.visible) {
        switch (e.key) {
          case "ArrowRight":
            this.next();
            break;
          case "ArrowLeft":
            this.prev();
            break;
          case "ArrowDown":
          case "ArrowUp":
          case " ":
            e.preventDefault();
            break;
          case "Escape":
            this.hide();
            break;
        }
      }
    }
  },

  mounted() {
    window.addEventListener("keydown", this.onKeydown);
    this.onResize();
  },

  destroyed() {
    window.removeEventListener("keydown", this.onKeydown);
  }
};
</script>

<style scoped>
.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.lightbox {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.rounded-card {
  border-radius: 5px;
}

.lightbox-image img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.lightbox-height {
  height: 100%;
  cursor: pointer;
}

.invisible {
  display: none;
}

.lightbox-nav {
  position: absolute;
  z-index: 999;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}

.lightbox-page {
  color: white;
  font-size: 1em;
  width: 100%;
  position: absolute;
  z-index: 999;
}

.lightbox-close-button {
  color: white;
  position: absolute;
  z-index: 999;
  cursor: pointer;
}

.lightbox-line1 {
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 1.2em;
  width: 100%;
  position: absolute;
  z-index: 999;
}

.lightbox-line2 {
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 1em;
  width: 100%;
  position: absolute;
  z-index: 999;
}

.lightbox-line3 {
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 1em;
  width: 100%;
  position: absolute;
  z-index: 999;
}

.lightbox-images-more {
  color: white;
  font-size: 1.2em;
  width: 100%;
  height: 100%;
  z-index: 999;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
}

.floor-plan-description {
  font-size: 1em;
}
</style>