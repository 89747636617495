import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { auth } from "@/services/fb";

Vue.config.productionTip = false;

let app;
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App),
      mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
    }).$mount("#app");
  }
});

const themeLight = vuetify.framework.theme.themes.light;

// themeLight.primary = "#641E16";
themeLight.primary = "#2b4f38";
themeLight.secondary = "#e91e63";
themeLight.accent = "#82B1FF";
themeLight.error = "#FF5252";
themeLight.info = "#2196F3";
themeLight.success = "#4CAF50";
themeLight.warning = "#FB8C00";
