<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <h1 class="primary--text text--lighten-1" :class="{'h1-sm': $vuetify.breakpoint.xs}">
            Floor Plans
          </h1>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center" justify="center">
        <v-row align="center" justify="center">
          <v-col v-for="(image, i) in items" :key="i" sm="12" :md="image.width">
            <v-container px-5>
              <v-row align="center" justify="center">
                <v-card flat tile class="d-flex rounded-card">
                  <v-img :src="image.url"
                    :lazy-src="image.url" class="grey lighten-2">
                    <template v-slot:placeholder>
                      <v-layout fill-height align-center justify-center ma-0>
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-layout>
                    </template>
                  </v-img>
                </v-card>
              </v-row>
              <v-row align="center" justify="center">
                <span class="floor-plan-description primary--text text--darken-1" style="margin-top: 10px;">{{ image.line1 }}</span>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FloorPlans",

  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  }
};
</script>

<style scoped>
.floor-plan-description {
  font-size: 1em;
}
</style>