<template>
  <v-footer padless id="footer">
    <v-card class="flex" flat color="primary" dark>
      <v-card-actions class="justify-center">
        &copy;{{ new Date().getFullYear() }} {{ property.name }}
      </v-card-actions>
    </v-card>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FooterAdmin",

  computed: {
    ...mapGetters(["property"])
  }
};
</script>