<template>
  <v-footer padless id="footer">
    <v-card class="flex" flat color="primary" dark>
      <v-card-title class="justify-center primary lighten-1">
        <v-btn color="white" text v-for="item in pageNavigation" :key="`${item.title}-footer-link`" :to="item.route" v-show="item.visible == true">
          {{ item.title }}
        </v-btn>
      </v-card-title>

      <v-card-actions class="justify-center">
        <v-container fluid class="padless">
          <v-row>
            <v-col cols="12" class="padless">
              <v-row align="center" justify="center">
                &copy;{{ new Date().getFullYear() }} {{ property.name }}
                <span class="hidden-xs-only mx-3"> | </span>
                <a href="https://getvirtualview.com" target="_blank" class="hidden-xs-only">
                  Website by Get Virtual View
                </a>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",

  computed: {
    ...mapGetters(["pageNavigation", "property"])
  }
};
</script>

<style scoped>
.small-button {
  font-size: 0.8em;
  margin-top: 1px;
  margin-left: 5px;
}

a:link,
a:visited,
a:hover,
a:active {
  color: white;
  text-decoration: none;
}
</style>