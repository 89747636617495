import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import store from "@/store";

const firebaseConfig = {
  apiKey: "AIzaSyA8POQhaqeQLNDjgW3DQ7ZDN-zgNkDenRw",
  authDomain: "forest-glen-apartments.firebaseapp.com",
  databaseURL: "https://forest-glen-apartments.firebaseio.com",
  projectId: "forest-glen-apartments",
  storageBucket: "forest-glen-apartments.appspot.com",
  messagingSenderId: "742980676692",
  appId: "1:742980676692:web:f1332cda85b9301a2c91c1",
  measurementId: "G-3680CQDTSB"
}

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
var storage = firebase.storage();

auth.onAuthStateChanged(user => {
  if (user) {
    user.getIdTokenResult().then(idTokenResult => {
      user.admin = idTokenResult.claims.admin;
    });

    store.dispatch("updateUser", user);
  } else {
    store.dispatch("updateUser", null);
  }

  store.dispatch("changeAuthReady");
});

export { db, auth, functions, storage };
